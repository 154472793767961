import React, { useState, useContext } from 'react';
import { graphql } from 'gatsby';
import headsetIcon from 'images/headset-gray.svg';
import ToolboxLayout from 'layouts/toolboxLayout';
import WebinarLayout from 'components/Page/WebinarTemplates/Layout';
import WebinarHero from 'components/Page/WebinarTemplates/Hero';
import WebinarRegisterMainContent from 'components/Page/WebinarTemplates/WebinarRegisterTemplate/MainContent';
import WebinarSpeakerCarousel from 'components/Page/WebinarTemplates/SpeakerCarousel';
import SweetScrollContext from 'utils/SweetScrollContext';

const WebinarRegisterTemplate = (props) => {
  const {
    data: { contentfulWebinar },
  } = props;
  const {
    inlineMarketingForm,
    keyTakeaways,
    mainContent,
    speakers,
    subtitle,
    webinarMonthDayYear,
    webinarTitle,
    webinarTime,
  } = contentfulWebinar;

  const breadcrumbs = {
    data: [
      {
        title: 'Toolbox',
        icon: null,
        url: '/toolbox',
      },
      {
        title: 'Webinars',
        icon: headsetIcon,
        url: '/webinar',
      },
      {
        title: 'Live Webinars',
        icon: null,
        url: null,
      },
    ],
    absolute: true,
    theme: 'dark',
    ignoreBookmark: true
  };
  contentfulWebinar.seo.pageCategory = 'Webinar Register';

  const [selSpeaker, setSelSpeaker] = useState(0);
  const { scrollToAnchor } = useContext(SweetScrollContext);

  const handleClickSpeaker = (index) => {
    if (document) {
      scrollToAnchor('#webinar-speakers--carousel');
    }

    setSelSpeaker(index);
  };

  return (
    <ToolboxLayout
      breadcrumbs={breadcrumbs}
      contentfulSeo={contentfulWebinar.seo}
      layout="toolbox"
      {...props}
    >
      <WebinarLayout showAddSocial={false}>
        <WebinarHero
          type="live"
          form={inlineMarketingForm}
          title={webinarTitle}
          date={webinarMonthDayYear}
          time={webinarTime}
          shouldRedirect
        />
        <WebinarRegisterMainContent
          subtitle={subtitle}
          mainContent={mainContent}
          keyTakeaways={keyTakeaways}
          speakers={speakers}
          onClickSpeaker={handleClickSpeaker}
        />
      </WebinarLayout>

      {speakers && <WebinarSpeakerCarousel speakers={speakers} selSpeaker={selSpeaker} />}
    </ToolboxLayout>
  );
};

export const WebinarRegisterPageTemplatePageQuery = graphql`
  query WebinarRegisterBySlug($slug: String!) {
    contentfulWebinar(seo: { pageUrl: { eq: $slug } }) {
      ...Webinar
    }
  }
`;

export default WebinarRegisterTemplate;
