import styled from 'styled-components';

const Container = styled.div`
  max-width: 1252px;
  margin: 0 auto;

  p {
    font-size: var(--font-size-16);
    color: var(--darkest);
  }
`;

const InnerContainer = styled.div`
  height: 100%;
  width: 65%;
  padding: 0 40px 50px 0;
  position: relative;
  margin: 0;

  @media (max-width: 767px) {
    width: 100%;
    padding: 0 0 40px;
  }
`;

export { Container, InnerContainer };
