import React from 'react';
import WebinarKeyTakeaways from 'components/Page/WebinarTemplates/KeyTakeaways';
import StyledRichText from 'components/RichText/StyledRichText';
import WebinarGuestSpeaker from '../../GuestSpeaker';

import { Container, InnerContainer } from './styles';

const WebinarRegisterMainContent = ({
  subtitle,
  mainContent,
  keyTakeaways,
  speakers,
  onClickSpeaker,
}) => {
  return (
    <Container>
      <InnerContainer>
        {speakers && <WebinarGuestSpeaker speakers={speakers} onClickSpeaker={onClickSpeaker} />}
        {subtitle && <h6>{subtitle}</h6>}
        {mainContent && <StyledRichText content={mainContent} />}
        {keyTakeaways && <WebinarKeyTakeaways keyTakeaways={keyTakeaways} />}
      </InnerContainer>
    </Container>
  );
};

export default WebinarRegisterMainContent;
